import React, { FC, useMemo } from 'react';
import { MultiSelect } from '../MultiSelect';
import { Spinner } from '@components/uikit/Spinner/Spinner';
import { useGetCamerasQuery } from '@store/api';
import { Option } from '@components/uikit/Checkboxes/Checkboxes';

export type CameraSelectOption = {
	value: string;
	label: string;
};

type CameraMultipleSelectProps = {
	selectedCameraIds?: string[];
	onChange?: (selectedValues: Option[]) => void;
	containerClassName?: string;
};

export const CameraMultipleSelect: FC<CameraMultipleSelectProps> = ({
	selectedCameraIds = [],
	onChange,
	containerClassName,
}) => {
	const { currentData, isLoading } = useGetCamerasQuery({
		offset: 0,
		limit: 100,
		with_ro_cameras: false,
	});

	const cameras: CameraSelectOption[] = useMemo(() => {
		if (!currentData?.data) {
			return [];
		}
		return currentData.data.map((cam) => {
			return {
				value: cam.camera_uuid,
				label: cam.camera_name ?? 'Без названия',
			};
		});
	}, [currentData]);

	return (
		<div className={['relative', containerClassName].filter(Boolean).join(' ')}>
			{isLoading && <Spinner />}
			<MultiSelect
				options={cameras}
				selectedValues={cameras.filter((camera) => {
					return selectedCameraIds.includes(camera.value);
				})}
				onChange={onChange}
				placeholder={cameras.length === 0 ? 'Нет доступных камер' : 'Выберите камеру'}
				icon="cameraVideo"
				title="Камера"
			/>
		</div>
	);
};

export const MemoizedCameraMultipleSelect = React.memo(CameraMultipleSelect);
