import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from '@components/uikit/Button/Button';
import { Icon, IconName } from '@components/uikit/Icon/Icon';
import { Checkboxes, Option } from '@components/uikit/Checkboxes/Checkboxes';

type MultiSelectProps = {
	options: Option[];
	selectedValues?: Option[];
	onChange?: (selectedValues: Option[]) => void;
	placeholder?: string;
	className?: string;
	icon?: IconName;
	title?: string;
};

export const MultiSelect: FC<MultiSelectProps> = ({
	options,
	selectedValues = [],
	onChange,
	placeholder = 'Выберите...',
	className,
	icon,
	title,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [tempSelection, setTempSelection] = useState<Option[]>(selectedValues);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const handleCheckboxChange = (value: string) => {
		setTempSelection((prev) => {
			const option = options.find((opt) => {
				return opt.value === value;
			});

			const isAlreadySelected = prev.some((item) => {
				return item.value === value;
			});

			if (!option) {
				return prev;
			}

			if (isAlreadySelected) {
				return prev.filter((item) => {
					return item.value !== value;
				});
			}
			return [...prev, option];
		});
	};

	const handleReset = () => {
		setTempSelection([]);
	};

	const changeVisibility = () => {
		return setIsOpen((prev) => {
			return !prev;
		});
	};

	const handleApply = () => {
		if (onChange !== undefined) {
			onChange(tempSelection);
		}
		setIsOpen(false);
	};

	return (
		<div className={['multi-select', className].filter(Boolean).join(' ')} ref={dropdownRef}>
			<div
				className="p-[12px] py-[12px] border-none bg-secondary-container rounded-[8px] cursor-pointer"
				onClick={changeVisibility}
			>
				<div className="font-label-large flex gap-2 text-secondary-40 items-center">
					{icon && <Icon name={icon} width={16} height={16} />}

					{tempSelection.length > 0 ? `Выбрано: ${tempSelection.length}` : placeholder}

					<Icon name="arrowBottomShort" className={`mr-0 ml-auto transition ease ${isOpen ? 'rotate-180' : ''}`} />
				</div>
			</div>

			{isOpen && (
				<div className="absolute z-20 py-[16px] bg-secondary-container rounded-[8px] mt-[8px] w-full left-0 top-full box-shadow-elevation-3 flex flex-col gap-3">
					{title && <div className="text-14 font-label-medium mx-[16px]">{title}</div>}
					<Checkboxes options={options} selectedValues={tempSelection} onChange={handleCheckboxChange} />
					<div className="flex flex-col mx-4 gap-2">
						<Button outline={true} variant="transparent" size="sm" onClick={handleReset}>
							Сбросить
						</Button>
						<Button onClick={handleApply} size="sm">
							Применить
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};
