import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { EventOverviewPageHeader } from '@components/event/EventOverviewPageHeader/EventOverviewPageHeader';
import { QueryTab } from '@components/uikit/Tab/QueryTab';
import { useSearchParams } from 'react-router-dom';
import { useInitialSearchParams } from '@hooks/useInitialSearchParams';
import { EventsOverviewZone } from '@components/events/EventsOverview/EventsOverviewZone';
import { EventsOverviewLine } from '@components/events/EventsOverview/EventsOverviewLine';
import { BucketSelect } from '@components/uikit/Select/BucketSelect/BucketSelect';

/**
 * Страница событий.
 */
export const EventOverviewPage = () => {
	useInitialSearchParams({
		detectionType: 'zones',
	});
	const [sectionParams] = useSearchParams();
	const section = sectionParams.get('detectionType') ?? '';

	return (
		<DashboardLayout>
			<DashboardContentLayout
				overrideHeaderRenderer={
					<div className={'flex items-center w-full'}>
						<div className={'flex items-center gap-x-4 w-full'}>
							<EventOverviewPageHeader />
							{section === 'zones' && (
								<div className={'flex items-center gap-x-2 grow'}>
									<BucketSelect className={'min-w-[264px]'} isClearable={true} size={'lg'} />
								</div>
							)}
						</div>
						<div className={'flex bg-primary-95 p-2 rounded-3 gap-1'}>
							<QueryTab variant={'pill'} queryName={'detectionType'} matchOn={'zones'}>
								Зоны детекции
							</QueryTab>
							<QueryTab variant={'pill'} queryName={'detectionType'} matchOn={'lines'}>
								Линии детекции
							</QueryTab>
						</div>
					</div>
				}
				drawDivider={false}
			>
				{section === 'zones' && <EventsOverviewZone />}
				{section === 'lines' && <EventsOverviewLine />}
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
