import { EventDateTimeWrapper } from '@components/event/EventDateTime/EventDateTimeWrapper';
import { EventDetatils } from '@components/event/EventDetails/EventDetails';
import { EventsList } from '@components/event/EventsList/EventsList';
import { EventFilterWrapper } from '@components/event/EventFilter/EventFilterWrapper';
import { EventTypeSelect } from '@components/uikit/Select/EventTypeSelect/EventTypeSelect';
import {
	CameraSelectOption,
	MemoizedCameraMultipleSelect,
} from '@components/uikit/Select/CameraMultipleSelect/CameraMultipleSelect';
import { useSearchParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useGetCamerasByBucketQuery, useGetCamerasQuery } from '@store/api';

export type SearchCameraEventsOverviewState = {
	id: string;
	title: string;
};

export const EventsOverviewZone = () => {
	const [sectionParams] = useSearchParams();
	const section = sectionParams.get('type') ?? '';
	const sectionBucket = sectionParams.get('bucket_id') ?? false;
	const bucket_id = sectionParams.get('bucket_id') ?? undefined;
	const { currentData: camerasData } = useGetCamerasByBucketQuery(bucket_id ?? '', { skip: bucket_id === undefined });
	const { currentData: allCameras } = useGetCamerasQuery({});

	const mapToCameraUUID = (cameras: { camera_uuid: string }[] = []) => {
		return cameras.map(({ camera_uuid }) => {
			return camera_uuid;
		});
	};

	const camerasId: string[] = useMemo(() => {
		if (camerasData?.cameras) {
			return mapToCameraUUID(camerasData.cameras);
		}
		return mapToCameraUUID(allCameras?.data);
	}, [camerasData, allCameras]);

	const [selectedCameras, setSelectedCameras] = useState<Nullable<Array<SearchCameraEventsOverviewState>>>(null);

	const onChangeCamera = (options: CameraSelectOption[]) => {
		if (options.length < 0) {
			setSelectedCameras(null);
		} else {
			setSelectedCameras(
				options.map((option) => {
					return {
						id: option.value,
						title: option.label,
					};
				}),
			);
		}
	};

	const camerasIds = useMemo(() => {
		return selectedCameras && section === 'old'
			? selectedCameras.map((camera) => {
					return camera.id;
			  })
			: camerasId;
	}, [selectedCameras, section, camerasId]);

	return (
		<div className={'flex flex-col gap-y-[32px] flex-grow h-full'}>
			<div className={'flex h-full'}>
				<section className={'flex flex-col gap-y-[20px] flex-grow p-4 bg-neutral-98 rounded-2 min-w-[782px]'}>
					<div className="flex gap-2">
						<EventTypeSelect containerClassName="min-w-[240px]" />
						{section === 'old' && !sectionBucket && (
							<MemoizedCameraMultipleSelect onChange={onChangeCamera} containerClassName="min-w-[240px]" />
						)}
						<div className="flex gap-2 ml-auto mr-0">
							<EventDateTimeWrapper />
							<EventFilterWrapper />
						</div>
					</div>
					<EventsList camerasIds={camerasIds} />
				</section>
				<section className={'flex flex-col min-w-fit h-fit sticky top-[24px]'}>
					<EventDetatils />
				</section>
			</div>
		</div>
	);
};
