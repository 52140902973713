import React, { ReactNode, useEffect } from 'react';
import { ReactComponent as Detector } from '@assets/illustrations/detector.svg';
import { InfinityListLoader } from '@components/uikit/InfinityListLoader/InfinityListLoader';
import { useInView } from 'react-intersection-observer';
import { useStoreDispatch, useStoreSelector } from '@store/store';
import { eventsSlice } from '@store/slices/eventsSlice';

type Event = { id: number; type: string };
export type EventListProps<T extends Event> = {
	events: T[];
	className?: string;
	children: (event: T) => ReactNode;
};

export function EventList<T extends Event>({ events, className: propClassName, children }: EventListProps<T>) {
	const className = propClassName ? propClassName : 'flex flex-col gap-y-[24px]';
	const dispatch = useStoreDispatch();
	const total = useStoreSelector((store) => {
		return store.events.total;
	});
	const offset = useStoreSelector((store) => {
		return store.events.offset;
	});
	const { ref, inView } = useInView();

	useEffect(() => {
		inView && dispatch(eventsSlice.actions.incOffset());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);

	return events.length > 0 ? (
		<ul className={className}>
			{events.map((event) => {
				return <li key={`${event.id}-${event.type}`}>{children(event)}</li>;
			})}
			<InfinityListLoader reachedEnd={total <= offset} ref={ref} />
		</ul>
	) : (
		<div
			className={'w-full h-[calc(100vh-20px-32px*4-46px-50px)] flex flex-col gap-y-[32px] items-center justify-center'}
		>
			<Detector />
			<p style={{ wordBreak: 'break-word' }} className={'font-headline-medium '}>
				В данный момент события отсутствуют
			</p>
		</div>
	);
}
