import { ROUTER } from '@constants/router';
import { useStoreSelector } from '@store/store';
import { useSearchParams } from 'react-router-dom';
import { EventsBadge } from '@components/uikit/Badge/EventsBadge/EventsBadge';

export const EventOverviewPageHeader = () => {
	const [sectionParams] = useSearchParams();
	const sectionBucket = sectionParams.get('bucket_id') ?? false;

	const newEventsLength = useStoreSelector((store) => {
		return store.events.newEvents;
	});

	return (
		<div className={'flex gap-x-[10px] items-center'}>
			<h1 className={'font-display-small text-primary-0 whitespace-nowrap'}>{ROUTER.EVENTS.TITLE}</h1>
			{!sectionBucket && <EventsBadge count={newEventsLength > 99 ? '99+' : newEventsLength} />}
		</div>
	);
};
