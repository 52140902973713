import { FC } from 'react';
import { Checkbox } from '@components/uikit/Checkbox/Checkbox';

export type Option = {
	value: string;
	label: string;
};

type CheckboxesProps = {
	options: Option[];
	selectedValues: Option[];
	onChange: (value: string) => void;
};

export const Checkboxes: FC<CheckboxesProps> = ({ options, selectedValues, onChange }) => {
	const isOptionSelected = (selectedValues: Option[], optionValue: string) => {
		return selectedValues.some((item) => {
			return item.value === optionValue;
		});
	};

	return (
		<div className="flex flex-col gap-1 overflow-x-scroll max-h-[216px]">
			{options.map((option) => {
				return (
					<Checkbox
						key={option.value}
						name={option.label}
						label={option.label}
						value={option.value}
						checked={isOptionSelected(selectedValues, option.value)}
						onChange={() => {
							return onChange(option.value);
						}}
						variant="item"
					/>
				);
			})}
		</div>
	);
};
