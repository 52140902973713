import React, { useMemo } from 'react';
import { Icon } from '@components/uikit/Icon/Icon';
import styles from './Checkbox.module.css';

export type CheckboxVariant = 'default' | 'item';

export type CheckboxProps = {
	name: string;
	label: string;
	value?: string;
	checked?: boolean | undefined;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	variant?: CheckboxVariant;
};

export const Checkbox = ({ checked, value, name, label, onChange, variant = 'default' }: CheckboxProps) => {
	const { className, classNameLabel, classNameCheckboxContainer } = useMemo(() => {
		return {
			className: variant === 'item' ? styles.checkboxItem : styles.checkbox,
			classNameLabel: variant === 'item' ? styles.labelItem : styles.label,
			classNameCheckboxContainer: checked ? styles.checkboxContainerActive : styles.checkboxContainer,
		};
	}, [variant, checked]);

	return (
		<div className={className}>
			{variant === 'item' && (
				<div className={classNameCheckboxContainer}>
					{checked && <Icon className={styles.checkboxIcon} name={'checkboxIcon'} width={14} height={10} />}
				</div>
			)}
			<label>
				<input checked={checked} type="checkbox" value={value} onChange={onChange} name={name} />
				<span className={classNameLabel}>{label}</span>
			</label>
		</div>
	);
};
