import React, { FC, memo, useCallback } from 'react';
import { Select } from '../Select';
import { SelectOption, SelectProps } from '@components/uikit/Select/Select.typedef';
import { useGetBucketsQuery } from '@store/api';
import { Spinner } from '@components/uikit/Spinner/Spinner';
import { useSearchParams } from 'react-router-dom';

type BucketSelectProps = Pick<
	SelectProps,
	'size' | 'label' | 'className' | 'isClearable' | 'placeholder' | 'isDisabled' | 'error'
> & {
	containerClassName?: string;
	configFilter?: (config: unknown) => boolean;
};

const RESET_FILTER_VALUE = 'RESET_FILTER';

export const BucketSelect: FC<BucketSelectProps> = memo(function ({
	containerClassName,
	configFilter,
	...selectProps
}) {
	const [searchParams, setSearchParams] = useSearchParams();
	const bucket_id = searchParams.get('bucket_id') ?? undefined;

	const onBucketChange = useCallback(
		(value: SelectOption | null) => {
			setSearchParams((prevState) => {
				if (value?.value === RESET_FILTER_VALUE) {
					prevState.delete('bucket_id');
				} else if (value?.value) {
					prevState.set('bucket_id', value.value);
				} else {
					prevState.delete('bucket_id');
				}

				return prevState;
			});
		},
		[setSearchParams],
	);

	const { currentData, isLoading } = useGetBucketsQuery({ limit: 100, offset: 0 });
	const bucketsOptions: NonNullable<SelectOption>[] = currentData?.data
		? currentData.data.reduce<{ value: string; label: string }[]>((acc, curr) => {
				if (curr.tags?.includes('stream')) {
					return [...acc, { value: curr.bucket_name, label: curr.bucket_caption }];
				}
				return acc;
		  }, [])
		: [];

	const resetOption: SelectOption = { value: RESET_FILTER_VALUE, label: 'Показать все' };
	const options = [resetOption, ...bucketsOptions];

	const selectedConfig =
		bucketsOptions.find(({ value }) => {
			return value === bucket_id;
		}) ?? null;

	return (
		<div className={['relative', containerClassName].filter(Boolean).join(' ')}>
			{isLoading && <Spinner />}
			<Select<SelectOption>
				valueContainerIcon={'boundingBox'}
				isClearable={true}
				isSearchable={true}
				placeholder={'Картотека'}
				onChange={onBucketChange}
				{...selectProps}
				options={options}
				value={selectedConfig}
			/>
		</div>
	);
});
