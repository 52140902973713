import { Badge } from '@components/uikit/Badge/Badge';

type EventsBadgeProps = {
	count: number | string;
};

export const EventsBadge = ({ count }: EventsBadgeProps) => {
	if (!count) return null;

	return (
		<Badge color="error" variant="filled" size="sm">
			{count}
		</Badge>
	);
};
