// OUTPUT: generate-icon-component.js
// ДАННЫЙ КОД СГЕНЕРИРОВАН АВТОМАТИЧЕСКИ (05.09.2024, 17:28:47)
//
// НИ В КОЕМ СЛУЧАЕ НЕ РЕДАКТИРУЙТЕ ЭТОТ ФАЙЛ,
// В ПРОТИВНОМ СЛУЧАЕ ПРИ ПОСЛЕДУЮЩЕЙ ГЕНЕРАЦИИ ИЗМЕНЕНИЯ БУДУТ УТЕРЯНЫ!

import React, { FC, SVGProps } from 'react';
import { ReactComponent as Matrix1 } from '@assets/icons/Matrix1.svg';
import { ReactComponent as Matrix10 } from '@assets/icons/Matrix10.svg';
import { ReactComponent as Matrix12 } from '@assets/icons/Matrix12.svg';
import { ReactComponent as Matrix4 } from '@assets/icons/Matrix4.svg';
import { ReactComponent as Matrix7 } from '@assets/icons/Matrix7.svg';
import { ReactComponent as Matrix9 } from '@assets/icons/Matrix9.svg';
import { ReactComponent as ambulanceAuto } from '@assets/icons/ambulance-auto.svg';
import { ReactComponent as appIndicator } from '@assets/icons/app-indicator.svg';
import { ReactComponent as archive } from '@assets/icons/archive.svg';
import { ReactComponent as areaDetection } from '@assets/icons/area-detection.svg';
import { ReactComponent as arrowCouterclockwise } from '@assets/icons/arrow-couterclockwise.svg';
import { ReactComponent as arrowLeftShort } from '@assets/icons/arrow-left-short.svg';
import { ReactComponent as arrowRightShort } from '@assets/icons/arrow-right-short.svg';
import { ReactComponent as aspectRatio } from '@assets/icons/aspect-ratio.svg';
import { ReactComponent as bell } from '@assets/icons/bell.svg';
import { ReactComponent as bookmark } from '@assets/icons/bookmark.svg';
import { ReactComponent as boundingBox } from '@assets/icons/bounding-box.svg';
import { ReactComponent as boxArrowRightFill } from '@assets/icons/box-arrow-right-fill.svg';
import { ReactComponent as boxArrowUpRight } from '@assets/icons/box-arrow-up-right.svg';
import { ReactComponent as building } from '@assets/icons/building.svg';
import { ReactComponent as calendarAll } from '@assets/icons/calendar-all.svg';
import { ReactComponent as calendar } from '@assets/icons/calendar.svg';
import { ReactComponent as calendar3Range } from '@assets/icons/calendar3-range.svg';
import { ReactComponent as cameraFill } from '@assets/icons/camera-fill.svg';
import { ReactComponent as cameraVideo } from '@assets/icons/camera-video.svg';
import { ReactComponent as carDetection } from '@assets/icons/car-detection.svg';
import { ReactComponent as car } from '@assets/icons/car.svg';
import { ReactComponent as chatRightQuoteFill } from '@assets/icons/chat-right-quote-fill.svg';
import { ReactComponent as checkAll } from '@assets/icons/check-all.svg';
import { ReactComponent as checkCircleFill } from '@assets/icons/check-circle-fill.svg';
import { ReactComponent as check } from '@assets/icons/check.svg';
import { ReactComponent as check2All } from '@assets/icons/check2-all.svg';
import { ReactComponent as check2Circle } from '@assets/icons/check2-circle.svg';
import { ReactComponent as check2 } from '@assets/icons/check2.svg';
import { ReactComponent as chevronCompactDoubleLeft } from '@assets/icons/chevron-compact-double-left.svg';
import { ReactComponent as chevronCompactDown } from '@assets/icons/chevron-compact-down.svg';
import { ReactComponent as chevronCompactRight } from '@assets/icons/chevron-compact-right.svg';
import { ReactComponent as chevronCompactUp } from '@assets/icons/chevron-compact-up.svg';
import { ReactComponent as clockHistory } from '@assets/icons/clock-history.svg';
import { ReactComponent as clock } from '@assets/icons/clock.svg';
import { ReactComponent as cloudDownload } from '@assets/icons/cloud-download.svg';
import { ReactComponent as commonAuto } from '@assets/icons/common-auto.svg';
import { ReactComponent as diagram3 } from '@assets/icons/diagram-3.svg';
import { ReactComponent as dog } from '@assets/icons/dog.svg';
import { ReactComponent as download } from '@assets/icons/download.svg';
import { ReactComponent as emojiSmile } from '@assets/icons/emoji-smile.svg';
import { ReactComponent as exclamationCircleFill } from '@assets/icons/exclamation-circle-fill.svg';
import { ReactComponent as exclamationCircle } from '@assets/icons/exclamation-circle.svg';
import { ReactComponent as exclamationDiamondFill } from '@assets/icons/exclamation-diamond-fill.svg';
import { ReactComponent as exclamationTriangleFill } from '@assets/icons/exclamation-triangle-fill.svg';
import { ReactComponent as face } from '@assets/icons/face.svg';
import { ReactComponent as filePlay } from '@assets/icons/file-play.svg';
import { ReactComponent as filter } from '@assets/icons/filter.svg';
import { ReactComponent as folder } from '@assets/icons/folder.svg';
import { ReactComponent as fullscreen } from '@assets/icons/fullscreen.svg';
import { ReactComponent as geoAlt } from '@assets/icons/geo-alt.svg';
import { ReactComponent as infoCircle } from '@assets/icons/info-circle.svg';
import { ReactComponent as info } from '@assets/icons/info.svg';
import { ReactComponent as info2Circle } from '@assets/icons/info2-circle.svg';
import { ReactComponent as lineDetection } from '@assets/icons/line-detection.svg';
import { ReactComponent as listUlSquare } from '@assets/icons/list-ul-square.svg';
import { ReactComponent as listUl } from '@assets/icons/list-ul.svg';
import { ReactComponent as list } from '@assets/icons/list.svg';
import { ReactComponent as mic } from '@assets/icons/mic.svg';
import { ReactComponent as pencilSquare } from '@assets/icons/pencil-square.svg';
import { ReactComponent as pencil } from '@assets/icons/pencil.svg';
import { ReactComponent as peopleDetection } from '@assets/icons/people-detection.svg';
import { ReactComponent as personCheckFill } from '@assets/icons/person-check-fill.svg';
import { ReactComponent as person } from '@assets/icons/person.svg';
import { ReactComponent as pinAngleFill } from '@assets/icons/pin-angle-fill.svg';
import { ReactComponent as pinAngle } from '@assets/icons/pin-angle.svg';
import { ReactComponent as plug } from '@assets/icons/plug.svg';
import { ReactComponent as plug2 } from '@assets/icons/plug2.svg';
import { ReactComponent as plusCircle } from '@assets/icons/plus-circle.svg';
import { ReactComponent as plus } from '@assets/icons/plus.svg';
import { ReactComponent as power } from '@assets/icons/power.svg';
import { ReactComponent as search } from '@assets/icons/search.svg';
import { ReactComponent as share } from '@assets/icons/share.svg';
import { ReactComponent as shuffle } from '@assets/icons/shuffle.svg';
import { ReactComponent as sliders } from '@assets/icons/sliders.svg';
import { ReactComponent as sortDownFill } from '@assets/icons/sort-down-fill.svg';
import { ReactComponent as sos } from '@assets/icons/sos.svg';
import { ReactComponent as spinner } from '@assets/icons/spinner.svg';
import { ReactComponent as starFill } from '@assets/icons/star-fill.svg';
import { ReactComponent as star } from '@assets/icons/star.svg';
import { ReactComponent as table } from '@assets/icons/table.svg';
import { ReactComponent as tag } from '@assets/icons/tag.svg';
import { ReactComponent as telegram } from '@assets/icons/telegram.svg';
import { ReactComponent as threeDotsVertical } from '@assets/icons/three-dots-vertical.svg';
import { ReactComponent as threeDots } from '@assets/icons/three-dots.svg';
import { ReactComponent as trashFill } from '@assets/icons/trash-fill.svg';
import { ReactComponent as userSearch } from '@assets/icons/user-search.svg';
import { ReactComponent as volumeDown } from '@assets/icons/volume-down.svg';
import { ReactComponent as volumeUp } from '@assets/icons/volume-up.svg';
import { ReactComponent as x } from '@assets/icons/x.svg';
import { ReactComponent as arrowBottomShort } from '@assets/icons/arrow-bottom-short.svg';
import { ReactComponent as checkboxIcon } from '@assets/icons/checkbox-icon.svg';

export type IconName =
	| 'Matrix1'
	| 'Matrix10'
	| 'Matrix12'
	| 'Matrix4'
	| 'Matrix7'
	| 'Matrix9'
	| 'ambulanceAuto'
	| 'appIndicator'
	| 'archive'
	| 'areaDetection'
	| 'arrowBottomShort'
	| 'arrowCouterclockwise'
	| 'arrowLeftShort'
	| 'arrowRightShort'
	| 'aspectRatio'
	| 'bell'
	| 'bookmark'
	| 'boundingBox'
	| 'boxArrowRightFill'
	| 'boxArrowUpRight'
	| 'building'
	| 'calendarAll'
	| 'calendar'
	| 'calendar3Range'
	| 'cameraFill'
	| 'cameraVideo'
	| 'carDetection'
	| 'car'
	| 'chatRightQuoteFill'
	| 'checkAll'
	| 'checkCircleFill'
	| 'check'
	| 'checkboxIcon'
	| 'check2All'
	| 'check2Circle'
	| 'check2'
	| 'chevronCompactDoubleLeft'
	| 'chevronCompactDown'
	| 'chevronCompactRight'
	| 'chevronCompactUp'
	| 'clockHistory'
	| 'clock'
	| 'cloudDownload'
	| 'commonAuto'
	| 'diagram3'
	| 'dog'
	| 'download'
	| 'emojiSmile'
	| 'exclamationCircleFill'
	| 'exclamationCircle'
	| 'exclamationDiamondFill'
	| 'exclamationTriangleFill'
	| 'face'
	| 'filePlay'
	| 'filter'
	| 'folder'
	| 'fullscreen'
	| 'geoAlt'
	| 'infoCircle'
	| 'info'
	| 'info2Circle'
	| 'lineDetection'
	| 'listUlSquare'
	| 'listUl'
	| 'list'
	| 'mic'
	| 'pencilSquare'
	| 'pencil'
	| 'peopleDetection'
	| 'personCheckFill'
	| 'person'
	| 'pinAngleFill'
	| 'pinAngle'
	| 'plug'
	| 'plug2'
	| 'plusCircle'
	| 'plus'
	| 'power'
	| 'search'
	| 'share'
	| 'shuffle'
	| 'sliders'
	| 'sortDownFill'
	| 'sos'
	| 'spinner'
	| 'starFill'
	| 'star'
	| 'table'
	| 'tag'
	| 'telegram'
	| 'threeDotsVertical'
	| 'threeDots'
	| 'trashFill'
	| 'userSearch'
	| 'volumeDown'
	| 'volumeUp'
	| 'x';

export type IconProps = Omit<SVGProps<SVGElement>, 'name'> & { name: IconName };

export const componentMap: Record<IconName, FC> = {
	Matrix1,
	Matrix10,
	Matrix12,
	Matrix4,
	Matrix7,
	Matrix9,
	ambulanceAuto,
	appIndicator,
	archive,
	areaDetection,
	arrowBottomShort,
	arrowCouterclockwise,
	arrowLeftShort,
	arrowRightShort,
	aspectRatio,
	bell,
	bookmark,
	boundingBox,
	boxArrowRightFill,
	boxArrowUpRight,
	building,
	calendarAll,
	calendar,
	calendar3Range,
	cameraFill,
	cameraVideo,
	carDetection,
	car,
	chatRightQuoteFill,
	checkAll,
	checkCircleFill,
	checkboxIcon,
	check,
	check2All,
	check2Circle,
	check2,
	chevronCompactDoubleLeft,
	chevronCompactDown,
	chevronCompactRight,
	chevronCompactUp,
	clockHistory,
	clock,
	cloudDownload,
	commonAuto,
	diagram3,
	dog,
	download,
	emojiSmile,
	exclamationCircleFill,
	exclamationCircle,
	exclamationDiamondFill,
	exclamationTriangleFill,
	face,
	filePlay,
	filter,
	folder,
	fullscreen,
	geoAlt,
	infoCircle,
	info,
	info2Circle,
	lineDetection,
	listUlSquare,
	listUl,
	list,
	mic,
	pencilSquare,
	pencil,
	peopleDetection,
	personCheckFill,
	person,
	pinAngleFill,
	pinAngle,
	plug,
	plug2,
	plusCircle,
	plus,
	power,
	search,
	share,
	shuffle,
	sliders,
	sortDownFill,
	sos,
	spinner,
	starFill,
	star,
	table,
	tag,
	telegram,
	threeDotsVertical,
	threeDots,
	trashFill,
	userSearch,
	volumeDown,
	volumeUp,
	x,
};

export const Icon: FC<IconProps> = ({ name, ...propsProxy }) => {
	const Component = componentMap[name];
	return <Component {...propsProxy} />;
};
