import { useGetBucketConfigQuery } from '@store/api';
import { eventsSelector } from '@store/slices/eventsSlice';
import { useStoreSelector } from '@store/store';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

type UseDateEventsFinderProps = {
	bucket_id?: string;
	type: string;
	startDate?: string;
	endDate?: string;
};

const ARCHIVE_TIME = dayjs().hour(0).minute(0).second(0).millisecond(0).toISOString();

export const useDateEventsFinder = ({ bucket_id, type, startDate, endDate }: UseDateEventsFinderProps) => {
	const { currentData: bucketConfig } = useGetBucketConfigQuery(bucket_id ?? '', { skip: !bucket_id });
	const { max_ts } = useStoreSelector(eventsSelector);
	const [sectionParams] = useSearchParams();
	const sectionType = sectionParams.get('type') ?? '';

	const bucketInfo = () => {
		if (!bucketConfig || !bucketConfig.timestamp_created) {
			return { isActive: sectionType !== 'old', endTime: '', duration: 0 };
		}

		const createdDate = dayjs(bucketConfig.timestamp_created);
		if (!createdDate.isValid()) {
			return { isActive: sectionType !== 'old', endTime: '', duration: 0 };
		}

		const durationTag = bucketConfig.tags.find((item) => {
			return item.includes('duration');
		});
		const duration = Number(durationTag?.replace('duration:', '') || 0);

		const endTime = createdDate.add(duration, 'hour');
		return {
			isActive: !duration || endTime.isAfter(dayjs()),
			endTime: endTime.toISOString(),
			duration,
		};
	};

	const timeCalculate = () => {
		if (!bucketConfig || !bucketConfig.timestamp_created) {
			return ARCHIVE_TIME;
		}

		const createdDate = dayjs(bucketConfig.timestamp_created);
		if (!createdDate.isValid()) {
			return ARCHIVE_TIME;
		}

		const moveToArchiveTag = bucketConfig.tags.find((item) => {
			return item.includes('archiveMoving');
		});

		const moveToArchive = Number(moveToArchiveTag?.replace('archiveMoving:', '') || 0);

		if (!moveToArchive) {
			return ARCHIVE_TIME;
		}

		const hoursAmountPassedTotal = dayjs().diff(createdDate, 'hour', true);
		const hoursAmountToArchivePoint = Math.floor(hoursAmountPassedTotal / moveToArchive) * moveToArchive;

		return bucketInfo().isActive
			? createdDate.add(hoursAmountToArchivePoint, 'hour').toISOString()
			: bucketInfo().endTime;
	};

	const minTime = () => {
		if (type === 'new') {
			const date = timeCalculate();

			if (startDate) {
				return Date.parse(startDate) > Date.parse(date) ? new Date(startDate).toISOString() : date;
			}

			return date;
		}

		return startDate ? new Date(startDate).toISOString() : startDate;
	};

	const maxTime = () => {
		if (type === 'new') {
			const date = bucketInfo().isActive ? max_ts ?? undefined : timeCalculate();

			if (endDate) {
				return !date || Date.parse(endDate) < Date.parse(date) ? new Date(endDate).toISOString() : date;
			}
			return date;
		}

		const date = timeCalculate();
		if (endDate) {
			return Date.parse(endDate) < Date.parse(date) ? new Date(endDate).toISOString() : date;
		}

		return date;
	};

	return { minTime: minTime(), maxTime: maxTime(), isActive: bucketInfo().isActive };
};
